<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("LABELS.LABELS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openLabelCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_LABELS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{ $t("LABELS.ADD_LABEL") }}</span>
            </base-button>

            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_LABELS)"
              :objectType="'labels'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <label-list-table
        @onViewLabel="openLabelViewModal"
        @onEditLabel="openLabelEditModal"
        @onDeleteLabel="deleteLabel"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewLabelModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewLabelModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'LABEL_VIEW'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("LABELS.VIEW_LABEL") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openLabel"
                :objectType="'labels'"
                :objectId="openLabel.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <!-- <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openLabelEditModal(openLabel)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="delete" @click="deleteLabel(openLabel)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown> -->

              <button class="close" @click="closeLabelModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-label-component
              v-if="openLabel"
              :labelId="openLabel.id"
              @onEditLabel="openLabelEditModal"
              @onDeleteLabel="deleteLabel"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditLabelModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditLabelModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'LABEL'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("LABELS.EDIT_LABEL") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openLabelViewModal(openLabel)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeLabelModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-label-component
              v-if="openLabel"
              :labelId="openLabel.id"
              @onViewLabel="openLabelViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddLabelModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddLabelModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'LABEL'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("LABELS.ADD_LABEL") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeLabelModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-label-component @onViewLabel="openLabelViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import LabelListTable from "./partials/LabelListTable.vue";
import EditLabelComponent from "./components/EditLabelComponent.vue";
import AddLabelComponent from "./components/AddLabelComponent.vue";
import ViewLabelComponent from "./components/ViewLabelComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    LabelListTable,
    NotificationSubscription,
    EditLabelComponent,
    AddLabelComponent,
    ViewLabelComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const labelId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewLabelModalOpened = false;
    let isEditLabelModalOpened = false;
    let isAddLabelModalOpened = false;
    let openLabel = null;
    if (labelId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewLabelModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditLabelModalOpened = true;
      }
      openLabel = { id: labelId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddLabelModalOpened = true;
    }
    return {
      isViewLabelModalOpened: isViewLabelModalOpened,
      isEditLabelModalOpened: isEditLabelModalOpened,
      isAddLabelModalOpened: isAddLabelModalOpened,
      openLabel: openLabel,
      renderKey: 1,
    };
  },

  methods: {
    openLabelCreateModal() {
      this.closeLabelModal();
      this.isAddLabelModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Labels",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openLabelViewModal(label, reRender = false) {
      this.closeLabelModal();
      this.openLabel = label;
      this.isViewLabelModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Labels",
          query: { id: this.openLabel.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openLabelEditModal(label) {
      this.closeLabelModal();
      this.openLabel = label;
      this.isEditLabelModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Labels",
          query: { id: this.openLabel.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeLabelModal() {
      this.isAddLabelModalOpened = false;
      this.isViewLabelModalOpened = false;
      this.isEditLabelModalOpened = false;
      this.openLabel = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Labels",
          query: {},
        }).href
      );
    },

    async deleteLabel(label) {
      const confirmation = await swal.fire({
        text: this.$t("LABELS.DELETE_THIS_LABEL"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("labels/destroy", label.id);
          this.renderKey++;
          this.closeLabelModal();
          this.$notify({
            type: "success",
            message: this.$t("LABELS.LABEL_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
